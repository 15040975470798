import "./App.css";
import Header from "./components/navigation/Header";
import Home from "./components/home/Home";
import { Routes, Route } from "react-router-dom";
import CaseStudy from "./components/caseStudy/CaseStudy";
import ContactMe from "./components/contact/ContactMe";
import Footer from "./components/navigation/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/casestudy/:id" element={<CaseStudy />} />
        <Route path="/contact" element={<ContactMe />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
