/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Landing from "./Landing";
import Project from "./Project";
import Experience from "./Experience";
import TechStack from "./TechStack";

export default function Home() {
  return (
    <>
      <div className="container mx-auto">
        <Landing />
        <Project />
        <TechStack />
        {/* <Experience /> */}
      </div>
    </>
  );
}
