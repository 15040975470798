/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import workpal from "./../../assets/workpal.png";
import clubspace from "./../../assets/clubspace.png";
import piccle from "./../../assets/Idea.png";
import { Link } from "react-router-dom";

const features = [
  {
    id: 1,
    name: "WorkPal",
    category: "Mobile App Development",
    image: workpal,
    description:
      "Meeting Assistant App for Small Businesses and Startups: Automating Meeting Management with AI and Blockchain",
    icon: CloudArrowUpIcon,
  },
  {
    id: 2,
    name: "ClubSpace",
    category: "Web App Development",
    image: clubspace,
    description:
      "The Ultimate Club Management App: Empowering Clubs with Seamless Administration and Member Engagement",
    icon: LockClosedIcon,
  },
  {
    id: 3,
    name: "Piccle",
    category: "Web App Development",
    image: piccle,
    description:
      "Rediscover Everyday Items: Guess-the-Object Game with Unique Gameplay and Reward System",
    icon: ServerIcon,
  },
];

export default function Project() {
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="container mx-auto px-6 pt-10">
          <h1 className="text-2xl font-semibold text-gray-800 dark:text-white lg:text-4xl">
            Projects
          </h1>

          <div className="mt-8 lg:-mx-12 lg:flex xl:mt-16">
            <div className="mt-8 flex-1 lg:mx-12 lg:mt-0">
              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-2">
                {features.map((feature) => (
                  <Link
                    to={"/casestudy/" + feature.id}
                    className="group relative block bg-black"
                  >
                    <img
                      alt="Developer"
                      src={feature.image}
                      className="absolute inset-0 h-full w-full object-cover opacity-40 transition-opacity group-hover:opacity-60"
                    />

                    <div className="relative p-4 sm:p-6 lg:p-8">
                      <p className="text-sm text-left font-medium uppercase tracking-widest text-pink-500">
                        {feature.category}
                      </p>

                      <p className="text-xl text-left font-bold text-white sm:text-2xl">
                        {feature.name}
                      </p>

                      <div className="mt-32 sm:mt-48 lg:mt-64">
                        <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                          <p className="text-sm text-white">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
