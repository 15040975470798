/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useParams } from "react-router";
import ReactMarkdown from "react-markdown";
import workpal from "./../../assets/workpal.png";
import clubspace from "./../../assets/clubspace.png";
import piccle from "./../../assets/Idea.png";
import { Link } from "react-router-dom";

const projects = [
  {
    id: 1,
    title: "Workpal",
    projectCover: workpal,
    description: ``,
    markdown: `## Title: Meeting Assistant App for Small Businesses and Startups: Automating Meeting Management with AI and Blockchain
  
  **Introduction:**
  Our meeting assistant app is designed to cater to the needs of small businesses and startups that strive for efficient meeting management and information organization. By leveraging AI technology, the app simplifies the process of recording meetings, transcribing meeting notes, and assigning tasks to team members. Additionally, the integration of blockchain ensures document integrity and security by storing encrypted transcripts.
  
  **Meeting Recording and Transcription:**
  The meeting assistant app provides an automated solution for recording meetings, eliminating the need for manual note-taking. With the app running in the background, users can focus on the discussion while all audio is recorded. The app's advanced speech recognition technology transcribes the recorded audio into text, enabling users to have accurate and searchable meeting notes.
  
  **Task Assignment and Management:**
  To streamline collaboration and task allocation, the app employs AI algorithms to identify action items and assign tasks to team members. By analyzing the meeting content, the app recognizes tasks, decisions, and deadlines, and automatically generates task assignments. This feature ensures that all participants have a clear understanding of their responsibilities and timelines.
  
  **AI-Powered Insights:**
  Our meeting assistant app goes beyond basic transcription by offering AI-powered insights. It can analyze meeting data, identify patterns, and provide actionable insights to improve meeting efficiency and productivity. These insights help businesses identify recurring issues, track progress, and make data-driven decisions for better outcomes.
  
  **Blockchain Integration for Document Integrity:**
  To guarantee document integrity and prevent tampering, the app utilizes blockchain technology. The meeting transcripts are encrypted and securely stored on the blockchain, ensuring that they remain unaltered and accessible only to authorized individuals. This provides an additional layer of security and transparency for sensitive information.
  
  **Benefits for Small Businesses and Startups:**
  - Time and Effort Savings: The app eliminates the need for manual note-taking, saving time and allowing participants to engage more fully in discussions.
  - Enhanced Organization: Meeting notes and transcripts are stored in an easily searchable format, ensuring important information is readily accessible.
  - Improved Collaboration: Automated task assignment and reminders facilitate better teamwork and accountability.
  - Data-Driven Decision Making: AI-powered insights enable businesses to identify trends, address challenges, and optimize meeting outcomes.
  - Document Security: Blockchain integration ensures the integrity and confidentiality of meeting transcripts, mitigating the risk of unauthorized access or alterations.
  
  **Conclusion:**
  Our meeting assistant app provides small businesses and startups with a comprehensive solution for efficient meeting management. By automating the recording, transcription, and task assignment processes, and integrating blockchain for document integrity, the app empowers teams to focus on productive discussions and facilitates seamless collaboration. With this app, businesses can streamline their meetings, improve productivity, and make more informed decisions.`,
  },
  {
    id: 2,
    title: "ClubSpace",
    projectCover: clubspace,
    description:
      "A tiny VS Code extension made up of a few commands that generate and insert lorem ipsum text into a text file. To use the extension, open the command palette (F1 or cmd/ctrl+shift+p, type lorem ipsum and select to insert either a line or paragraph",
    markdown: `## Title: The Ultimate Club Management App: Empowering Clubs with Seamless Administration and Member Engagement
  
  **Introduction:**
  The Ultimate Club Management App is a comprehensive solution designed to streamline club management and enhance member engagement. The app offers features to create and manage clubs effortlessly, create and join events tailored to members' interests, and provides valuable insights to club admins regarding member engagement and participation.
  
  **Club Creation and Information Management:**
  The app simplifies the process of creating new clubs by allowing users to input all necessary information, including the club name, description, and other relevant details. Club admins can easily set up and customize their club's profile, making it attractive and informative for potential members. The app ensures that clubs have a strong online presence to effectively communicate their purpose and attract like-minded individuals.
  
  **Event Creation and Attendance:**
  With the Ultimate Club Management App, club admins can effortlessly create events that align with their club's interests and goals. Members can easily browse and join these events within the app, ensuring seamless coordination and participation. The app provides features such as event notifications, reminders, and RSVP tracking, enabling both admins and members to stay updated and engaged.
  
  **Insights into Member Engagement:**
  To empower club admins with valuable insights, the app offers analytics and reporting features. Admins can access data and metrics that provide a comprehensive view of member engagement and participation levels. This information allows admins to assess the success of club initiatives, identify areas for improvement, and tailor future events to better meet the needs and interests of their members.
  
  **Benefits for Clubs and Members:**
  1. Efficient Club Administration: The app simplifies club creation and management, reducing administrative burden and allowing admins to focus on fostering a vibrant club community.
  
  2. Enhanced Member Engagement: By providing a platform for creating and joining events relevant to members' interests, the app fosters increased engagement and participation among club members.
  
  3. Improved Communication: The app facilitates seamless communication between club admins and members through event notifications, reminders, and in-app messaging, ensuring all relevant information is easily accessible.
  
  4. Data-Driven Decision Making: The insights and analytics offered by the app enable admins to make informed decisions about club activities, improving the overall experience for members and optimizing club performance.
  
  5. Streamlined Event Management: The app simplifies event creation, attendance tracking, and coordination, ensuring efficient planning and execution of club events.
  
  **Conclusion:**
  The Ultimate Club Management App provides clubs with a powerful tool to streamline administration, foster member engagement, and gain valuable insights into member participation. By simplifying club creation, event management, and offering data-driven analytics, the app empowers club admins to create vibrant communities while ensuring members have access to relevant events and seamless communication. This app revolutionizes the way clubs are managed, resulting in enhanced engagement, improved experiences, and thriving club communities.`,
  },
  {
    id: 3,
    title: "Piccle",
    projectCover: piccle,
    description: ``,
    markdown: `## Title: Rediscover Everyday Items: Guess-the-Object Game with Unique Gameplay and Reward System

  **Introduction:**
    The Guess-the-Object Game is an exciting and engaging experience that challenges players to rediscover everyday items in a fun and interactive way. With its unique gameplay and rewarding system, this game offers a refreshing twist on traditional gaming, encouraging players to explore their surroundings, test their knowledge, and win exciting rewards.
    
  **Gameplay and Object Discovery:**
    The Guess-the-Object Game presents players with images or clues that hint at a specific everyday item. Players must use their observational skills, creativity, and knowledge to identify the object within a given timeframe. The game prompts players to think outside the box and explore various possibilities before submitting their answer.
    
  **Reward System:**
    To enhance the gaming experience and motivate players, the Guess-the-Object Game features a rewarding system. Players earn points or virtual currency for correctly identifying objects, progressing through levels, or achieving certain milestones. These rewards can be redeemed for in-game items, customization options, or even real-world prizes, creating a sense of achievement and excitement.
    
  **Rediscovering Everyday Items:**
    The game's main focus is to encourage players to rediscover the beauty and significance of everyday items. By presenting objects in unique ways and challenging players to identify them, the game prompts a fresh perspective on familiar items, fostering a sense of appreciation and curiosity about the world around us.
    
  **Educational and Entertainment Value:**
    The Guess-the-Object Game offers a combination of educational and entertainment value. While players engage in an enjoyable gaming experience, they also expand their knowledge and observation skills by identifying everyday items in unique contexts. This blend of fun and learning makes the game suitable for players of all ages.
    
  **Social Interaction and Competitions:**
    The game fosters social interaction and friendly competition by allowing players to challenge their friends, participate in leaderboards, or join multiplayer game modes. Players can compare their scores, share their achievements, and collaborate to solve more challenging object puzzles, adding a social element to the gameplay experience.
    
  **Benefits for Players:**
  1. Fun and Engaging Gameplay: The Guess-the-Object Game provides an entertaining and immersive experience that captivates players' attention and keeps them engaged for hours.
    
  2. Rediscovering Everyday Items: The game prompts players to look at familiar objects in a new light, fostering appreciation for the beauty and significance of everyday items.
    
  3. Rewarding System: The game's rewarding system adds an element of excitement and motivation, allowing players to earn virtual currency, in-game items, or real-world rewards as they progress.
    
  4. Educational Value: Through object identification challenges, the game enhances players' observation skills, knowledge, and creativity.
    
  5. Social Interaction and Competitions: The game enables players to connect with friends, participate in leaderboards, and engage in multiplayer modes, fostering a sense of community and healthy competition.
    
  **Conclusion:**
    The Guess-the-Object Game offers a unique and rewarding gaming experience that combines entertainment, education, and the rediscovery of everyday items. With its engaging gameplay, rewarding system, and social interaction features, the game provides players with hours of fun, knowledge expansion, and the opportunity to win exciting rewards. Embark on a journey of object exploration and enjoy the thrill of guessing correctly while uncovering the beauty in the ordinary.`,
  },
  {
    id: 4,
    title: "uxmarg",
    projectCover: "",
    description: ``,
    markdown: ``,
  },
];

export default function CaseStudy() {
  const { id } = useParams();
  let projectSelected;
  projects.forEach((e) => {
    if (e.id === parseInt(id)) {
      projectSelected = e;
    }
  });
  console.log(projectSelected);
  return (
    <>
      <div class="flex flex-col bg-white m-20">
        <img
          src={projectSelected.projectCover}
          alt=""
          class="object-cover w-full rounded-lg h-96"
        />

        <div class="flex items-center justify-center flex-1">
          <div class="max-w-xl px-4 py-8 mx-auto">
            <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {projectSelected.title}
            </h1>

            <p class="mt-4 text-gray-500">
              <ReactMarkdown className="prose">
                {projectSelected.markdown}
              </ReactMarkdown>
            </p>

            <Link
              to="/"
              class="inline-block px-5 py-3 mt-6 text-sm font-medium text-white bg-indigo-600 rounded hover:bg-indigo-700 focus:outline-none focus:ring"
            >
              Go Back Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
