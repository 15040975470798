import React from "react";

export default function TechStack() {
  return (
    <>
      <section>
        <div className="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
          <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
            <div className="mx-auto max-w-lg lg:mx-0 ltr:lg:text-left rtl:lg:text-right">
              <h2 className="text-3xl font-bold sm:text-4xl">Tech Stack</h2>

              <p className="mt-4 text-gray-600">
                My skills and experience with Angular, React, Node, and the MERN
                stack, that you are a full-stack JavaScript developer with
                expertise in building complex, scalable, and performant web
                applications.
              </p>
            </div>

            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
              <a
                className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="/accountant"
              >
                <span className="inline-block rounded-lg bg-gray-50 p-3">
                  <i
                    className="fa-brands fa-angular"
                    style={{ fontSize: "36px" }}
                  ></i>
                </span>

                <h2 className="mt-2 font-bold">Angular</h2>
              </a>

              <a
                className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="/accountant"
              >
                <span className="inline-block rounded-lg bg-gray-50 p-3">
                  <i
                    className="fa-brands fa-react"
                    style={{ fontSize: "36px" }}
                  ></i>
                </span>

                <h2 className="mt-2 font-bold">React</h2>
              </a>

              <a
                className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="/accountant"
              >
                <span className="inline-block rounded-lg bg-gray-50 p-3">
                  <i
                    className="fa-brands fa-node"
                    style={{ fontSize: "36px" }}
                  ></i>
                </span>

                <h2 className="mt-2 font-bold">NodeJs</h2>
              </a>

              <a
                className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="/accountant"
              >
                <span className="inline-block rounded-lg bg-gray-50 p-3">
                  <i
                    className="fa-brands fa-envira"
                    style={{ fontSize: "36px" }}
                  ></i>
                </span>

                <h2 className="mt-2 font-bold">MongoDB</h2>
              </a>

              <a
                className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="/accountant"
              >
                <span className="inline-block rounded-lg bg-gray-50 p-3">
                  <i className="fa-solid fa-s" style={{ fontSize: "36px" }}></i>
                </span>

                <h2 className="mt-2 font-bold">Storybook</h2>
              </a>

              <a
                className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                href="/accountant"
              >
                <span className="inline-block rounded-lg bg-gray-50 p-3">
                  <i
                    className="fa-brands fa-wordpress"
                    style={{ fontSize: "36px" }}
                  ></i>
                </span>

                <h2 className="mt-2 font-bold">Wordpress</h2>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
